import React from "react";
import Login from "../../pages/newlogin";
import Register from "../../pages/newregister";
import { Route, Switch, Redirect } from "react-router-dom";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    };
  }

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  render() {
    return (
      <Switch style={{ width: "100%" }}>
        {routes.map((prop, key) => {
          return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
        })}
        <Redirect to="/auth/login" />
      </Switch>
    );
  }
}
const routes = [
  {
    name: "Login",
    path: "/login",
    layout: "/auth",
    component: Login,
    icon: "nc-icon nc-tile-56",
  },
  {
    name: "Register",
    path: "/register",
    layout: "/auth",
    component: Register,
    icon: "nc-icon nc-tile-56",
  },
];

export default Auth;
