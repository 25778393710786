import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";

import Close from "@mui/icons-material/Close";
import { Modal, Box, Typography, Grid } from "@mui/material";
import { collection, database, where, query } from "../db/firebase";
import { getCountFromServer } from "firebase/firestore";
import StatusCard from "../components/status-card/StatusCard";
import ThemeAction from "../redux/actions/ThemeAction";
import Table from "../components/table/Table";
import Badge from "../badge/Badge";
import "./dashboard.css";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Chat";
import Twilio from "../components/twilio/Twilio";

import image from "../assets/images/dashboardHome.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const chartOptions = {
  series: [
    {
      name: "Online Customers",
      data: [40, 70, 10, 80, 36, 80, 46, 98, 72],
    },
    {
      name: "Store Customers",
      data: [49, 26, 53, 68, 34, 65, 13, 65],
    },
  ],

  options: {
    color: ["#fca779", "#fe7a7e"],
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    legend: {
      position: "top",
    },
    grid: {
      show: false,
    },
  },
};

const topCustomers = {
  head: ["user", "total orders", "total spending"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const renderCustomerHead = (item, index) => {
  return <th key={index}>{item}</th>;
};

const renderCustomerBody = (item, index) => (
  <tr key={index}>
    <td>{item.user}</td>
    <td>{item.order}</td>
    <td>{item.price}</td>
  </tr>
);

const latestOrders = {
  header: ["order id", "user", "total price", "date", "status"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const orderStatus = {
  shipping: "primary",
  pending: "warning",
  paid: "success",
  refund: "danger",
};

const renderOrderHead = (item, index) => {
  return <th key={index}>{item}</th>;
};
const renderOrderBody = (item, index) => {
  return (
    <tr key={index}>
      <td>{item.id}</td>
      <td>{item.user}</td>
      <td>{item.price}</td>
      <td>{item.date}</td>
      <td>
        <Badge type={orderStatus[item.status]} content={item.status} />
      </td>
    </tr>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();

  const themeReducer = useSelector((state) => state.ThemeReducer.mode);

  const [uid, setUid] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [usersCount, setUsersCount] = useState(0);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(ThemeAction.getTheme());
  }, [dispatch]);

  useEffect(() => {
    const getCounts = async () => {
      const uid = await localStorage.getItem("uid");
      setUid(uid);

      let userRef = query(
        collection(database, "users"),
        where("builder", "==", uid)
      );

      // get user count
      getCountFromServer(userRef).then((snapshot) => {
        setUsersCount(snapshot.data().count);
      });
    };
    getCounts();
  }, []);

  return (
    <div>
      <h2 className="page-header">Dashboard</h2>
      <div className="row">
        <div className="col-12">
          <div className="dashboard-header-wrapper">
            <div className="dashboard-header-image">
              <img src={image} alt="Home" className="dashboard-image" />
            </div>
            <div className="dashboard-header-text">
              <div className="header-top"></div>
              <br></br>
              <h4>
                Redefining the home experience through intelligent software
              </h4>
            </div>
          </div>
        </div>
        <div className="col-6 fullWidthOnMobile">
          <div className="row">
            <div className="col-6">
              <StatusCard
                count={usersCount}
                icon="bx bx-user-pin"
                title="Total Customers"
              />
            </div>
            <div className="col-6">
              <StatusCard icon="bx bx-cart" count={"63"} title="Daily visits" />
            </div>
            <div className="col-6">
              <StatusCard
                icon="bx bx-dollar-circle"
                count="$2,632"
                title="Total earnings"
              />
            </div>
            <div className="col-6">
              <StatusCard
                icon="bx bx-receipt"
                count="136"
                title="Total orders"
              />
            </div>
          </div>
        </div>
        <div className="col-6 fullWidthOnMobile">
          <div className="card full-height">
            <Chart
              options={
                themeReducer === "theme-mode-dark"
                  ? {
                      ...chartOptions.options,
                      theme: { mode: "dark" },
                    }
                  : {
                      ...chartOptions.options,
                      theme: { mode: "light" },
                    }
              }
              series={chartOptions.series}
              type="line"
              height="100%"
            />
          </div>
        </div>
        <div className="col-4 fullWidthOnMobile">
          <div className="card">
            <div className="card__header">
              <h3>top customers</h3>
            </div>
            <div className="card__body">
              <Table
                headData={topCustomers.head}
                renderHead={(item, index) => renderCustomerHead(item, index)}
                bodyData={topCustomers.body}
                renderBody={(item, index) => renderCustomerBody(item, index)}
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
        <div className="col-8 fullWidthOnMobile">
          <div className="card">
            <div className="card__header">
              <h3>latest orders</h3>
            </div>
            <div className="card__body">
              <Table
                headData={latestOrders.header}
                renderHead={(item, index) => renderOrderHead(item, index)}
                bodyData={latestOrders.body}
                renderBody={(item, index) => renderOrderBody(item, index)}
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
      </div>
      <Fab
        color="primary"
        aria-label="add"
        id="fab"
        style={{ position: "fixed", right: "10px", top: "90vh" }}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        id="twilio-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id="modal-box">
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            id="model-grid"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Send a concierge message to a home owner.
            </Typography>
            <Close onClick={handleClose} style={{ cursor: "pointer" }} />
          </Grid>
          <Twilio uid={uid} handleClose={handleClose} />
        </Box>
      </Modal>
    </div>
  );
};

export default Dashboard;
