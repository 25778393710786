import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import './twilio.css';

function Twilio({ handleClose, uid }) {
  const [person, setPerson] = React.useState('');
  const [recipient, setRecipient] = React.useState('');
  const [message, setMessage] = useState(`Hey ${person}!

You have been invited to join to HX family.

Below is the link to connect all of your home services. All you need to do is complete a profile and a few questions about what services you want. Then your concierge will text you to get everything setup for you!
  
https://services.unytehome.com/register-page?invite=${uid}`);

  const sendText = () => {
    if (recipient && message) {
      alert(`Sending message to ${recipient}`);
      fetch(
        `https://fast-plains-39678.herokuapp.com/send-text?recipient=${recipient}&textmessage=${message}`
      )
        .then(() => {
          handleClose();
          alert('Message Sent');
        })
        .catch((err) => console.error(err));
    } else {
      alert('Please enter a recipient and message');
    }
  };

  return (
    <div className="App">
      <TextField
        fullWidth
        value={person}
        variant="outlined"
        id="outlined-basic"
        label="Name Of Contact"
        style={{ marginTop: 30 }}
        onChange={(e) => setPerson(e.target.value)}
      />
      <TextField
        fullWidth
        value={recipient}
        variant="outlined"
        id="outlined-basic"
        label="Home Owners Phone Number"
        style={{ marginTop: 10 }}
        onChange={(e) => setRecipient(e.target.value)}
      />
      <TextField
        fullWidth
        multiline
        rows={10}
        value={message}
        variant="outlined"
        style={{ marginTop: 10 }}
        label="Type Your Message"
        id="outlined-basic-multiline"
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button
        onClick={sendText}
        style={{
          marginTop: 20,
          backgroundColor: 'skyblue',
          width: 200,
          borderRadius: 20,
        }}
        variant="contained"
        fullWidth
      >
        Send
      </Button>
    </div>
  );
}

export default Twilio;
