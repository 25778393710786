import "./profile.css";
import React from "react";
import { Button, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { database, getDoc, doc, updateDoc, setDoc } from "../db/firebase";

function User({ history }) {
  const [zip, setzip] = useState("");
  const [state, setState] = useState("");
  const [lname, setlname] = useState("");
  const [fname, setfname] = useState("");
  const [address, setaddress] = useState("");
  const [website, setwebsite] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [businessName, setbusinessName] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.ThemeReducer.user);

  const getProfile = useCallback(async () => {
    const uid = await localStorage.getItem("uid");
    if (uid) {
      const docSnap = await getDoc(doc(database, "builders", uid));
      if (docSnap.exists()) {
        if (docSnap.data().zip) setzip(docSnap.data().zip);
        if (docSnap.data().fname) setfname(docSnap.data().fname);
        if (docSnap.data().lname) setlname(docSnap.data().lname);
        if (docSnap.data().state) setState(docSnap.data().state);
        if (docSnap.data().website) setwebsite(docSnap.data().website);
        if (docSnap.data().address) setaddress(docSnap.data().address);
        if (docSnap.data().phoneNumber) {
          setphoneNumber(docSnap.data().phoneNumber);
        }
        if (docSnap.data().businessName) {
          setbusinessName(docSnap.data().businessName);
        }
        dispatch({ type: "SET_USER", payload: docSnap.data() });
      } else {
        console.log("no user");
        await localStorage.removeItem("uid");
        history.replace("auth/login");
      }
    } else {
      history.replace("/auth/login");
    }
  }, [history, dispatch]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const updateProfile = async () => {
    const uid = await localStorage.getItem("uid");
    updateDoc(doc(database, "builders", uid), {
      lname,
      fname,
      businessName,
      website,
      phoneNumber,
      address,
      zip,
      state,
    }).then(() => {
      alert("Profile Updated");
      window.location.reload();
    });
  };

  const [key, setKey] = useState("");

  useEffect(() => {
    getKey();
  }, []);

  const getKey = async () => {
    const docSnap = await getDoc(doc(database, "setting", "authKey"));
    if (docSnap.exists()) setKey(docSnap.data().key);
  };

  const onSubmit = async () => {
    await setDoc(doc(database, "setting", "authKey"), { key });
    alert("Key Updated & Required to fetch data from Api's");
  };

  return (
    <div className="profileRow">
      <div className="profileCol">
        <Card className="card-user">
          <div className="card-user-image">
            <img
              className="card-user-img"
              src={
                "https://buildings.honeywell.com/content/dam/hbtbt/en/images/horizontal/street-view-buildings-2880x1440.jpg"
              }
              alt="card user imgage"
            />
          </div>
          <CardBody>
            <div className="author">
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img
                  className="avatar"
                  src={
                    user?.userImg
                      ? user?.userImg
                      : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                  }
                  alt="avatar"
                />
                <h5 className="title">{user?.businessName}</h5>
              </a>
              <p className="description">{`${user?.fname} ${user?.lname}`}</p>
              <p className="description">{user?.email}</p>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="profileCol">
        <Card className="card-user">
          <CardHeader>
            <CardTitle className="heading">Edit Profile</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="row">
              <TextField
                value={fname}
                onChange={(e) => setfname(e.target.value)}
                className="formInput"
                label="First Name"
                variant="outlined"
              />
              <TextField
                value={lname}
                onChange={(e) => setlname(e.target.value)}
                className="formInput"
                label="Last Name"
                variant="outlined"
              />
            </div>
            <div className="row">
              <TextField
                value={businessName}
                onChange={(e) => setbusinessName(e.target.value)}
                className="formInput"
                label="BusinessName"
                variant="outlined"
              />
              <TextField
                value={website}
                onChange={(e) => setwebsite(e.target.value)}
                className="formInput"
                label="Website"
                variant="outlined"
              />
            </div>
            <div className="row">
              <TextField
                value={phoneNumber}
                onChange={(e) => setphoneNumber(e.target.value)}
                className="formInput"
                label="Phone Number"
                variant="outlined"
              />
              <TextField
                value={address}
                onChange={(e) => setaddress(e.target.value)}
                className="formInput"
                label="Address"
                variant="outlined"
              />
            </div>
            <div className="row">
              <TextField
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="formInput"
                label="State"
                variant="outlined"
              />
              <TextField
                value={zip}
                onChange={(e) => setzip(e.target.value)}
                className="formInput"
                label="Zip Code"
                variant="outlined"
              />
            </div>

            <br />

            <Button
              variant="contained"
              style={{ background: "#55ADFF" }}
              onClick={updateProfile}
            >
              Update Profile
            </Button>
          </CardBody>
        </Card>
        <div className="card">
          <TextField
            value={key}
            onChange={(e) => setKey(e.target.value)}
            fullWidth
            id="outlined-basic"
            label="Api Auth Key"
            variant="outlined"
          />
          <Button
            onClick={onSubmit}
            fullWidth
            style={{ marginTop: "10px", backgroundColor: "#58B4FB" }}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default User;
