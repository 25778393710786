const ThemeReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_MODE":
      return {
        ...state,
        mode: action.payload,
      };
    case "SET_COLOR":
      return {
        ...state,
        color: action.payload,
      };

    case "SET_USERS":
      return {
        ...state,
        users: action.payload,
      };

    case "SET_UID":
      return {
        ...state,
        uid: action.payload,
      };

    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default ThemeReducer;
