import * as React from "react";
import "./register.css";
import { Button, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  createUserWithEmailAndPassword,
  database,
  auth,
  setDoc,
  doc,
} from "../db/firebase";
import hizzyLogo from "../assets/images/hizzy_logo_white.png";
import Swal from "sweetalert2";

export default function NewRegister({ history }) {
  const [fname, setFName] = React.useState("");
  const [lname, setLName] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [state, setState] = React.useState("");
  const [psw, setPsw] = React.useState("");
  const [showPsw, setShowPsw] = React.useState(false);

  const getUid = React.useCallback(async () => {
    const uid = localStorage.getItem("uid");
    if (uid) history.replace("/admin/dashboard");
  }, [history]);

  React.useEffect(() => {
    getUid();
  }, [getUid]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      email === "" ||
      businessName === "" ||
      fname === "" ||
      lname === "" ||
      psw === "" ||
      phone === "" ||
      address === "" ||
      city === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill all the fields!",
      });
    } else {
      await createUserWithEmailAndPassword(auth, email, psw)
        .then(async (user) => {
          await setDoc(doc(database, "builders", user.user.uid), {
            createdAt: Date.now(),
            email,
            lname,
            fname,
            businessName,
            phoneNumber: phone,
            username: fname + " " + lname,
            address,
            city,
            zip,
            state,
            website,
          })
            .then(async () => {
              await localStorage.setItem("uid", user.user.uid);
              await localStorage.setItem("isNew", true);
              history.replace("/admin/dashboard");
              alert("Registration Successful");
            })
            .catch((error) => {
              Swal.fire({ icon: "error", title: "Oops...", text: error });
            });
        })
        .catch((error) => {
          Swal.fire({ icon: "error", title: "Oops...", text: error });
        });
    }
  };

  return (
    <div className="signUp-wrapper">
      <img
        alt="bg"
        className="signUp-bg"
        src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
      />
      <div className="signUp-box">
        <div className="signUp-body">
          <img src={hizzyLogo} alt="hizzy Logo" className="hizzyLogo" />

          <div className="signUpStyle">
            <input
              name="fName"
              type="text"
              value={fname}
              autoComplete="fname"
              placeholder="First name"
              onChange={(e) => setFName(e.target.value)}
            />
            <input
              name="lName"
              type="text"
              value={lname}
              autoComplete="lname"
              placeholder="Last name"
              onChange={(e) => setLName(e.target.value)}
            />
          </div>
          <div className="signUpStyle">
            <input
              name="businessName"
              type="text"
              value={businessName}
              placeholder="Business Name"
              onChange={(e) => setBusinessName(e.target.value)}
            />
            <input
              name="website"
              type="text"
              value={website}
              placeholder="Website name"
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div className="signUpStyle">
            <input
              name="phone"
              type="number"
              value={phone}
              placeholder="Phone number"
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              name="address"
              type="text"
              value={address}
              placeholder="Address"
              autoComplete="address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="signUpStyle">
            <input
              name="city"
              type="text"
              value={city}
              placeholder="City"
              autoComplete="city"
              onChange={(e) => setCity(e.target.value)}
            />
            <div className="signUpStyle2">
              <input
                name="zip"
                type="text"
                value={zip}
                autoComplete="zip"
                placeholder="Zip code"
                onChange={(e) => setZip(e.target.value)}
              />
              <input
                name="state"
                type="text"
                value={state}
                placeholder="State"
                autoComplete="state"
                onChange={(e) => setState(e.target.value)}
              />
            </div>
          </div>
          <div className="signUpStyle">
            <input
              name="email"
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              name="psw"
              value={psw}
              placeholder="Password"
              autoComplete="current-password"
              type={showPsw ? "text" : "password"}
              onChange={(e) => setPsw(e.target.value)}
            />
            <IconButton
              className="signUpswIcon"
              onClick={() => setShowPsw(!showPsw)}
            >
              {showPsw ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className="signUpButStyle"
          >
            Sign Up
          </Button>

          <p className="policyTxt">
            By creating an account, you agree and accept our Terms and Privacy
            Policy
          </p>
        </div>
        <div className="login-foot">
          <p>
            Already have an account?<a href="newlogin"> Login</a>
          </p>
        </div>
      </div>
    </div>
  );
}
