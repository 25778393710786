import React from "react";
import Map from "../pages/map";
import Users from "../pages/Users";
import Invite from "../pages/Invite";
import Profile from "../pages/Profile";
import Analytics from "../pages/Analytics";
import Dashboard from "../pages/Dashboard";
import Notification from "../pages/Notification";
import { Route, Switch } from "react-router-dom";

const Routes = () => (
  <Switch>
    <Route path="/admin/dashboard" exact component={Dashboard} />
    <Route path="/admin/users" component={Users} />
    <Route path="/admin/analytics" component={Analytics} />
    <Route path="/admin/invite-users" component={Invite} />
    <Route path="/admin/profile" component={Profile} />
    <Route path="/admin/map" component={Map} />
    <Route path="/admin/notifications" component={Notification} />
  </Switch>
);

export default Routes;
