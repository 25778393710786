import {
  doc,
  where,
  query,
  addDoc,
  getDocs,
  database,
  deleteDoc,
  collection,
} from "../db/firebase";
import "./notifications.css";
import { Card, CardHeader } from "reactstrap";
import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";

export default function Notification() {
  const [title, setTitle] = useState("");
  const [notification, setNotification] = useState("");
  const [allNotifiction, setAllNotification] = useState([]);

  const addNotification = async (e) => {
    e.preventDefault();
    if (title !== "") {
      Notify(title, notification);
      let time = Date.now();
      let ar = [...allNotifiction];
      let notiObj = { notification, title, time };
      await addDoc(collection(database, "notifications"), notiObj);
      ar.push(notiObj);
      setTitle("");
      setNotification("");
      setAllNotification(ar);
    } else {
      alert("Fill the field to send notification");
    }
  };

  const Notify = async (text, message) => {
    let uid = await localStorage.getItem("uid");
    let users = [];
    getDocs(
      query(collection(database, "users"), where("builder", "==", uid))
    ).then(async (docSnap) => {
      docSnap.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
      for (let i = 0; i < users.length; i++) {
        if (users[i].pushToken) {
          await fetch("https://exp.host/--/api/v2/push/send", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Accept-encoding": "gzip, deflate",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              to: users[i].pushToken,
              sound: "default",
              title: text,
              body: message,
            }),
          });
        }
      }
    });
  };

  const remove = async (id) => {
    await deleteDoc(doc(database, "notification", id));
    let newAr = allNotifiction.filter((e) => e.id !== id);
    setAllNotification(newAr);
  };

  useEffect(() => {
    let ar = [];
    getDocs(collection(database, "notifications")).then((docSnap) => {
      docSnap.forEach((doc) => ar.push({ ...doc.data(), id: doc.id }));
      setAllNotification(ar);
    });
  }, []);

  return (
    <div className="content">
      <Card>
        <CardHeader className="notificationHeading">
          Send Notification
        </CardHeader>
        <TextField
          fullWidth
          type="text"
          name="title"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          type="text"
          fullWidth
          name="notification"
          id="notification"
          label="Notification"
          value={notification}
          style={{ marginBottom: 30 }}
          onChange={(e) => setNotification(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={addNotification}
          style={{ background: "#55ADFF" }}
        >
          Send
        </Button>
      </Card>
      <Card>
        <CardHeader className="notificationHeading">
          All Notification
        </CardHeader>
        {!!allNotifiction.length ? (
          allNotifiction?.map((note, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                borderBottom: "1px solid silver",
                paddingBottom: "5px",
              }}
            >
              <h4>{note.title}</h4>
              <p>{note.notification}</p>
              <i
                onClick={() => remove(note.id)}
                className="bx bx-trash notificationDeleteBtn"
              ></i>
            </div>
          ))
        ) : (
          <div>
            <h3 style={{ color: "silver", textAlign: "center" }}>
              No Notification Yet
            </h3>
          </div>
        )}
      </Card>
    </div>
  );
}
