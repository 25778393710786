import {
  doc,
  limit,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  collection,
  startAfter,
} from "firebase/firestore";
import {
  Box,
  Grid,
  Modal,
  InputLabel,
  Typography,
  Button,
  NativeSelect,
} from "@mui/material";
import "./users.css";
import moment from "moment";
import React, { useEffect } from "react";
import { database } from "../db/firebase";
import Close from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Users = () => {
  const [uid, setUid] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [services, setServices] = React.useState([]);
  const [lastVisible, setLastVisible] = React.useState(null);

  const handleOpen = (item) => {
    setOpen(true);
    setUser(item);
    getDoc(doc(database, "selectedServices", item.id))
      .then((doc) => {
        setServices(doc.data()?.services || []);
      })
      .catch((e) => alert(e.message));
  };

  useEffect(() => {
    const getUsers = async () => {
      const uid = await localStorage.getItem("uid");
      setUid(uid);
      getDocs(
        query(
          collection(database, "users"),
          where("builder", "==", uid),
          orderBy("createdAt", "desc"),
          limit(8)
        )
      ).then((docSnap) => {
        setLastVisible(docSnap.docs[docSnap.docs.length - 1]);
        setUsers(docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
    };

    getUsers();
  }, []);

  const getNextPage = async () => {
    if (lastVisible) {
      const docSnap = await getDocs(
        query(
          collection(database, "users"),
          where("builder", "==", uid),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(8)
        )
      );
      setPage(page + 1);
      setLastVisible(docSnap.docs[docSnap.docs.length - 1]);
      let newUsers = docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setUsers((prev) => [...prev, ...newUsers]);
    }
  };

  const tableHead = [
    "Image",
    "Name",
    "Email",
    "Phone",
    "Selected Services",
    "Created Date",
  ];

  return (
    <div>
      <div className="col-12">
        <h2 className="page-header">Customers</h2>
        <div className="card">
          <div className="card__body">
            {users.length ? (
              <div className="table-wrapper">
                <table>
                  <thead style={{ backgroundColor: "#eeeeee" }}>
                    <tr>
                      {tableHead.map((item, idx) => (
                        <th key={idx}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => handleOpen(item)}
                        style={{
                          height: "60px",
                          cursor: "pointer",
                          backgroundColor: index % 2 !== 0 && "#fafafa",
                        }}
                      >
                        <td>
                          <img
                            alt="user"
                            width={40}
                            height={40}
                            style={{
                              marginTop: "10px",
                              marginLeft: "10px",
                              borderRadius: "100px",
                            }}
                            src={
                              item.userImg ||
                              item.image ||
                              "https://freepngimg.com/thumb/google/66726-customer-account-google-service-button-search-logo.png"
                            }
                          />
                        </td>
                        <td>{item?.username || "Hizzy User"}</td>
                        <td>{item?.email}</td>
                        <td>{item?.phoneNumber || "Not Provided"}</td>
                        <td>
                          {item?.activeServices ? "Selected" : "Not Selected"}
                        </td>
                        <td>{new Date(item.createdAt).toDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <table>
                  <thead style={{ backgroundColor: "#eeeeee" }}>
                    <tr>
                      {tableHead.map((item, idx) => (
                        <th key={idx}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                </table>
                <div
                  style={{
                    color: "gray",
                    margin: "20px 0",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  No More Users
                </div>
              </div>
            )}
            {users.length > 0 && (
              <div
                style={{
                  marginTop: 50,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button variant="contained" onClick={getNextPage}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id="modal-popup">
          <Grid item display="flex" justifyContent={"space-between"}></Grid>
          <Box id="user-wrapper">
            <Grid item id="top-modal">
              <div className="center">
                <img
                  src={
                    user?.userImg ||
                    user?.image ||
                    "https://freepngimg.com/thumb/google/66726-customer-account-google-service-button-search-logo.png"
                  }
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "100px",
                  }}
                  alt="users profile"
                  id="user-image"
                />
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ marginTop: 10 }}
                >
                  {user?.username || "Hizzy User"}
                </Typography>
              </div>
              <div className="blank-left">
                <Close
                  onClick={() => setOpen(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Grid>
            <div id="info-section">
              <div id="info-left">
                <Grid
                  item
                  ml={"10px"}
                  justifyContent="center"
                  display={"flex"}
                  flexDirection="column"
                >
                  <Typography variant="h6" component="h2" id="text">
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Email:
                    </span>{" "}
                    {user?.email}
                  </Typography>
                  <Typography variant="h6" component="h2" id="text">
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Phone number:
                    </span>{" "}
                    {user?.phoneNumber || "Not Provided"}
                  </Typography>
                  <Typography variant="h6" component="h2" id="text">
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Address:
                    </span>{" "}
                    {user?.address || user?.city || "Not Provided"}
                  </Typography>
                </Grid>
              </div>

              <div id="info-right">
                <div id="info-right">
                  <Grid
                    item
                    ml={"10px"}
                    justifyContent="center"
                    display={"flex"}
                    flexDirection="column"
                  >
                    {services && services.length > 0 ? (
                      <div id="info-right">
                        <h3
                          style={{ textAlign: "center", marginBottom: "5px" }}
                        >
                          Services
                        </h3>
                        <div id="info-right">
                          <Grid
                            item
                            ml={"10px"}
                            justifyContent="center"
                            display={"flex"}
                            flexDirection="column"
                          >
                            <div className="servicesRow">
                              {services.map((service, idx) => (
                                <div
                                  key={idx}
                                  style={{
                                    width: "20%",
                                    display: "flex",
                                    margin: "10px 10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="service-logo">
                                    <img
                                      src={service.image}
                                      alt={service.brand}
                                      className="serviceIMG"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                  <InputLabel id="demo-simple-select-label"></InputLabel>
                                  <NativeSelect
                                    style={{ fontSize: "14px" }}
                                    id="demo-simple-select"
                                    value={service.status}
                                  >
                                    {service.status === "Active" ? (
                                      <>
                                        <option value={"Active"}>Active</option>
                                        <option value={"Pending"}>
                                          Pending
                                        </option>
                                      </>
                                    ) : (
                                      <>
                                        <option value={"Pending"}>
                                          Pending
                                        </option>
                                        <option value={"Active"}>Active</option>
                                      </>
                                    )}
                                  </NativeSelect>
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "700",
                                      margin: "10px 0px",
                                    }}
                                  >
                                    Completetion Date:{" "}
                                    <span style={{ fontWeight: "400" }}>
                                      {moment(service.timestamp).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Package:
                                    <span style={{ fontWeight: "400" }}>
                                      {service.package
                                        .split(" ")
                                        .slice(0, 5)
                                        .join(" ")}
                                      {service.package.split(" ").length > 5
                                        ? "..."
                                        : ""}{" "}
                                    </span>
                                  </span>
                                  <Typography
                                    variant="h6"
                                    component="h2"
                                    style={{ marginTop: 10 }}
                                  >
                                    {service.name}
                                  </Typography>
                                </div>
                              ))}
                            </div>
                          </Grid>
                        </div>
                      </div>
                    ) : (
                      <div className="servicesRow">
                        <span
                          style={{
                            width: "100%",
                            color: "gray",
                            margin: "20px 0",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          No services to display
                        </span>
                      </div>
                    )}
                  </Grid>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Users;
