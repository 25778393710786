import "./map.css";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import React, { useState, useEffect } from "react";
import { where, query, collection, database, getDocs } from "../db/firebase";

const AnyReactComponent = () => <RoomIcon style={{ color: "red" }} />;

function Map() {
  const [users, setUsers] = useState("");
  const defaultProps = { center: { lat: 34.12834, lng: -117.20865 }, zoom: 9 };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const array = [];
    const uid = await localStorage.getItem("uid");

    getDocs(
      query(collection(database, "users"), where("builder", "==", uid))
    ).then((docSnap) => {
      docSnap.forEach((doc) => {
        if (doc.data()?.location) {
          array.push({ ...doc.data(), id: doc.id });
        }
      });
      setUsers(array);
    });
  };

  return (
    <div className="card" style={{ height: "80vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBeoDwIofUJph_Mwdi6GvX7PuLVOVzM9oc" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {!!users?.length &&
          users?.map((e, index) => {
            return (
              <AnyReactComponent
                key={index}
                lat={e.location.latitude}
                lng={e.location.longitude}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
}

export default Map;
