import {
  doc,
  auth,
  getDoc,
  database,
  signInWithEmailAndPassword,
} from "../db/firebase";
import "./register.css";
import * as React from "react";
import { Button, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import hizzyLogo from "../assets/images/hizzy_logo_white.png";
import Swal from "sweetalert2";

export default function NewLogin({ history }) {
  const [psw, setPsw] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [showPsw, setShowPsw] = React.useState(false);

  const getUid = React.useCallback(async () => {
    const uid = await localStorage.getItem("uid");
    if (uid) history.replace("/admin/dashboard");
  }, [history]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(event);
    }
  };
  React.useEffect(() => {
    getUid();
  }, [getUid]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && psw) {
      signInWithEmailAndPassword(auth, email, psw)
        .then(async (user) => {
          getDoc(doc(database, "builders", user.user.uid)).then((doc) => {
            if (doc.data()) {
              localStorage.setItem("uid", user.user.uid);
              history.replace("/admin/dashboard");
            } else Swal.fire("Oops...", "You are not a builder!", "error");
          });
        })
        .catch((error) => Swal.fire("Oops...", error, "error"));
    } else {
      Swal.fire("Oops...", "Please fill all the fields!", "error");
    }
  };

  return (
    <div className="login-wrapper">
      <img
        alt="bg"
        className="login-bg"
        src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
      />
      <div className="login-box">
        <div className="login-body">
          <img src={hizzyLogo} alt="hizzy Logo" className="hizzyLogo" />
          <input
            type="email"
            value={email}
            placeholder="Email"
            autoComplete="email"
            onKeyDown={handleKeyDown}
            className="loginEmailInpt"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="emailBox">
            <input
              value={psw}
              placeholder="Password"
              className="loginPswInpt"
              onKeyDown={handleKeyDown}
              autoComplete="current-password"
              type={showPsw ? "text" : "password"}
              onChange={(e) => setPsw(e.target.value)}
            />
            <IconButton
              className="loginPswIcon"
              onClick={() => setShowPsw(!showPsw)}
            >
              {showPsw ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className="loginButStyle"
          >
            Login
          </Button>
          <p className="policyTxt">
            By creating an account, you agree and accept our Terms and Privacy
            Policy
          </p>
        </div>
        <div className="login-foot">
          <p>
            Don't have an account?<a href="/auth/register"> Register</a>
          </p>
        </div>
      </div>
    </div>
  );
}
