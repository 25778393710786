import React, { useEffect } from "react";

import "./topnav.css";
import { Link } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import ThemeMenu from "../thememenu/ThemeMenu";
import { doc, getDoc, database } from "../../db/firebase";
import user_menu from "../../assets/JsonData/user_menus.json";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

const renderUserToggle = (name) => (
  <div className="topnav__right-user">
    <div className="topnav__right-user__image">
      <img
        src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
        alt="user"
      />
    </div>
    <div className="topnav__right-user__name">{name}</div>
  </div>
);

const Topnav = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = React.useState();

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onHandleClose = (item) => {
    setAnchorEl(null);
    props.history.push(item.route);
  };

  useEffect(() => {
    const getUserData = async () => {
      const uid = await localStorage.getItem("uid");
      const docSnap = await getDoc(doc(database, "builders", uid));
      if (docSnap.exists()) {
        setName(docSnap.data().fname + " " + docSnap.data().lname);
      } else {
        localStorage.removeItem("uid");
        props.history.replace("/auth/login");
        window.location.reload();
      }
    };

    getUserData();
  }, [props.history]);

  const logout = () => {
    localStorage.removeItem("uid");
    props.history.replace("/auth/login");
    window.location.reload();
  };

  return (
    <div className="topnav">
      <MenuIcon
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
        className="menuIcon"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={onHandleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {sidebar_items.map((item, index) => (
          <MenuItem key={index} onClick={() => onHandleClose(item)}>
            {item.display_name}
          </MenuItem>
        ))}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      <div className="topnav__search">
        <input type="text" placeholder="Search here..." />
        <i className="bx bx-search"></i>
      </div>
      <div className="topnav__right">
        <div className="topnav__right-item">
          <Dropdown
            customToggle={() => renderUserToggle(name)}
            contentData={user_menu}
            renderItems={(item, index) => (
              <Link to={item.route} key={index}>
                <div className="notification-item">
                  <i className={item.icon}></i>
                  <span>{item.content}</span>
                </div>
              </Link>
            )}
          />
        </div>
        <div className="topnav__right-item">
          <ThemeMenu />
        </div>
      </div>
    </div>
  );
};

export default Topnav;
