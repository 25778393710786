import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography, Chip, Box } from "@mui/material";
import { database, getDoc, doc } from "../db/firebase";
import "./Invite.css";

const Invite = () => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    getUrl();
  }, []);

  const getUrl = async () => {
    const uid = await localStorage.getItem("uid");
    const docSnap = await getDoc(doc(database, "setting", "inviteUrl"));
    if (docSnap.exists()) setUrl(`${docSnap.data().url}?invite=${uid}`);
  };

  return (
    <div>
      <h2 className="page-header">Invite Customers</h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Box
                display={"flex"}
                flexDirection="column"
                alignItems={"center"}
              >
                <Typography variant="h4" mb={"30px"} className="inviteHeading">
                  Invite your customers & manage them all in one page
                </Typography>
                {/* <CopyToClipboard text={url}>
                  <Chip
                    variant="outlined"
                    label={url}
                    style={{ width: "700px" }}
                  />
                </CopyToClipboard> */}
                <CopyToClipboard text={url}>
                  <Button
                    className="btn-round"
                    style={{
                      backgroundColor: "#01B5E7",
                      borderWidth: 0,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                      borderRadius: 50,
                      color: "white",
                      marginTop: "20px",
                    }}
                  >
                    ⚡️ In Development ⚡️
                  </Button>
                </CopyToClipboard>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
